import { useEffect, useState } from "react";
import { BannerSection } from "./style";
import { Spinner } from "../../../../assets/animations/spinner";
import { useTrack } from "../../../../services/hooks/useTrack";
import { useBlogFeed } from "../../../../services/hooks/useBlogFeed";

export function BlogBanner() {
  const [isLoading, setIsLoading] = useState(true);
  const { getBlogBanner } = useBlogFeed();
  const { trackEvent } = useTrack();

  const [data, setData] = useState();

  async function handleGetData() {
    setIsLoading(true);
    const res = await getBlogBanner();
    setData(res);
    setIsLoading(false);
  }

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <BannerSection>
      {!data ? (
        <div className="loading__container">
          <Spinner stroke_width="5" />
        </div>
      ) : (
        <>
          <div className="corner__text">{data.bannerTitle}</div>
          <div
            class="bannerLink"
            onClick={() => {
              trackEvent("Clicou no banner de novidades", {
                Link: data?.link,
              });
              window.open(data?.link, "_blank");
            }}
            data-bannerType="1"
          >
            <div class="banner">
              <div class="textArea">
                <div class="textContainer">
                  <p>{data.title}</p>
                </div>
                <svg
                  class="svg"
                  preserveAspectRatio="none"
                  viewBox="286.71 248.482 193.914 117.886"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M 474.926 268.469 C 470.221 282.668 462.395 295.652 452.015 306.484 C 462.373 317.467 470.092 330.634 474.592 344.997 C 477.182 351.99 479.193 359.136 480.624 366.368 L 410.804 366.368 C 409.407 358.447 407.323 350.645 404.565 343.051 C 399.931 329.277 392.326 316.671 382.28 306.112 C 392.334 295.574 399.94 282.98 404.565 269.214 C 407.022 262.448 408.945 255.516 410.323 248.482 L 480.49 248.482 C 479.128 255.257 477.27 261.937 474.926 268.469 Z"
                    fill="#fff"
                  />
                  <rect
                    x="286.71"
                    y="248.482"
                    width="161"
                    height="117.886"
                    fill="#fff"
                  />
                </svg>
              </div>
              <img class="banner__image" src={data.image} alt="" />
            </div>
          </div>
        </>
      )}
    </BannerSection>
  );
}
